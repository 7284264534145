import { useState, useEffect } from 'react';
import { createOrder } from '../../firebase.js';
import getName from '../../utils/getName.js';

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";

import OrderTable from './OrderTable.js';
import extractStyle from '../../utils/extractStyle.js';
import addresses from '../../warehouse-addresses';

const OrderCreateModal = ({initialQties, closeModal, reseller=false}) => {
    const [reference, setReference] = useState('');
    const [address, setAddress] = useState({
        name: '',
        line1: '',
        line2: '',
        city: '',
        region: '',
        country: '',
        postCode: '',
        phone: '',
    });
    const [order, setOrder] = useState();

    useEffect(() => {
        const today = new Date();
        const defaultRef = `${today.getFullYear().toString().slice(-2)}${(today.getMonth()+1) >= 10 ? today.getMonth()+1 : '0'+(today.getMonth()+1).toString()}P`;
        setReference(defaultRef);
    }, []);

    useEffect(() => {
        if (reseller) {
            return setOrder({shipments: [{
                items: [],
                payments: [],
                tracking: [],
                warehouse: "default",
            }], payments: []})
        }

        // Not Reseller Order
        if (initialQties && !order) {
            /* 
            Format order data as will be uploaded to firebase.
            {
                createdAt: timestamp,
                payments: [{
                    type: “merchandise”,
                    amount: number,
                    currency: string,
                }]
                reference: string,
                status: string,
                shipments: [{
                    address: {
                        line1: string,
                        line2: string,
                        city: string,
                        region: string,
                        country: string,
                        postalCode: string,
                    },
                    items: [{
                        name: string,
                        sku: string,
                        qty: number,
                        size: string,
                    }],
                    name: string,
                    statuses: [{
                        date: string,
                        details: {
                            contact: string,
                            error: null,
                            status: "success"|"failed"
                            type: "email"|"phone"
                        },
                        eventName: string
                        failed: number
                        successful: number
                    }],
                    payments: [{
                        type: “merchandise”, “shipping”, “duties”
                        amount: number,
                        currency: string,
                    }]
                    reference,
                    tracking,
                    }],
                    type: “production” | “stock”,
                }
            */
            const shipments = [];
            for (const sku of Object.keys(initialQties)) {
                for (const warehouse of Object.keys(initialQties[sku])) {
                    let shipmentIndex = shipments.findIndex(shipment => shipment.warehouse === warehouse);
                    if (shipmentIndex === -1) {
                        // Warehouse is not in shipment, create it
                        shipmentIndex = shipments.push({
                            address: addresses[warehouse],
                            items: [],
                            payments: [],
                            tracking: [],
                            warehouse,
                        }) - 1;
                    }
                    shipments[shipmentIndex].items.push({
                        name: getName(extractStyle(sku)),
                        size: parseInt(sku.slice(-2)),
                        sku,
                        qty: initialQties[sku][warehouse],
                    });
                }
            }
            
            setOrder({
                shipments,
                payments: [],
            });
        }
    }, []);

    useEffect(() => {
        // propagate reference changes to shipments
        if (!order || !reference) return;
        const shipmentReferenceNeedsUpdate = order.shipments.some(
            shipment => shipment.reference !== `${reference}-${shipment.warehouse}`
        );

        if (shipmentReferenceNeedsUpdate) {
            let updatedShipments = order.shipments.map(shipment => ({
                ...shipment,
                reference: `${reference}-${shipment.warehouse}`,
            }));
    
            setOrder(prevOrder => ({...prevOrder, shipments: updatedShipments}));
        }
    }, [reference, order]);

    const handleChange = (evt) => {
        setReference(evt.target.value);
    }

    const handleChangeAddress = (change) => {
        setAddress({...address, ...change});
    }

    const handleCreateOrder = async () => {
        closeModal();
        const forwardedOrder = {...order};
        if (reseller) {
            forwardedOrder.shipments[0].address = address;
        }
        await createOrder(reference, {...forwardedOrder}, 'production');
    }

    const handleOrderEdit = ({warehouse, sku, qty = 0, name, size}) => {
        const newOrder = {...order};
        if (warehouse) {
            const shipmentIndex = newOrder.shipments.findIndex(shipment => shipment.warehouse === warehouse);
            const item = newOrder.shipments[shipmentIndex].items.find((item) => item.sku === sku);
            item.qty = parseInt(qty);
        } else {
            // new item?
            newOrder.shipments.forEach(shipment => {
                if (shipment.items.some(item => item.sku === sku)) {
                    console.error(`SKU '${sku}' already exists in shipment '${shipment.warehouse}'`);
                    return;
                }
                shipment.items.push({ name, qty, sku, size });
            });
        }
        setOrder(newOrder);
    }

    const handleDeleteSku = (sku) => {
        const newOrder = {...order};
        newOrder.shipments.forEach(shipment => {
            shipment.items = shipment.items.filter(item => item.sku !== sku);
        });
        setOrder(newOrder);
    }

    return (
        <Stack spacing={"10px"}>
            <Typography>Entrer le numéro de référence</Typography>
            <TextField
                required
                id="reference"
                label="Référence"
                placeholder="yyMMP"
                onChange={handleChange}
                value={reference}
            />
            {reseller && <>
            <TextField
                required
                id="addressName"
                label="Name"
                onChange={(evt) => handleChangeAddress({ name: evt.target.value })}
                defaultValue={address.name}
            />
            <TextField
                required
                id="addressL1"
                label="Line 1"
                onChange={(evt) => handleChangeAddress({ line1: evt.target.value })}
                defaultValue={address.line1}
            />
            <TextField
                id="addressL2"
                label="Line 2"
                onChange={(evt) => handleChangeAddress({ line2: evt.target.value })}
                defaultValue={address.line2}
            />
            <TextField
                required
                id="addressCity"
                label="City"
                onChange={(evt) => handleChangeAddress({ city: evt.target.value })}
                defaultValue={address.city}
            />
            <TextField
                id="addressRegion"
                label="Region"
                onChange={(evt) => handleChangeAddress({ state: evt.target.value })}
                defaultValue={address.region}
            />
            <TextField
                required
                id="addressPostCode"
                label="Zip/PostCode"
                onChange={(evt) => handleChangeAddress({ postCode: evt.target.value })}
                defaultValue={address.postCode}
            />
            <TextField
                required
                id="addressCountry"
                label="Country"
                onChange={(evt) => handleChangeAddress({ country: evt.target.value })}
                defaultValue={address.country}
            />
            <TextField
                required
                id="addressPhone"
                label="Phone"
                onChange={(evt) => handleChangeAddress({ phone: evt.target.value })}
                defaultValue={address.phone}
            />
            </>}
            <OrderTable order={order} onOrderEdit={handleOrderEdit} onDelete={handleDeleteSku} />
            <Button
                disabled={reseller && !address.name} variant="contained" onClick={handleCreateOrder}>Confirmer</Button>
        </Stack>
    )
} 

export default OrderCreateModal;