import { createContext, useContext, useState, useEffect } from "react";
import { streamLineItems } from "../firebase";

const LineItemsContext = createContext();

// Custom hook to use the context
export const useLineItems = () => useContext(LineItemsContext);

// Context provider (streams data even when listView is unmounted)
export const LineItemsProvider = ({children}) => {
  const [lineItems, setLineItems] = useState([]);

  useEffect(() => {
    console.log("Streaming lineItems started...");

    // Steaming logic
    const unsubscribe = streamLineItems(async (snapshot) => {
      setLineItems(snapshot.docs);
    });
    return () => {
      console.log("Streaming lineItems stopped.");
      unsubscribe();
    };
  }, []);

  return (
    <LineItemsContext.Provider value={{ lineItems, setLineItems }}>
      {children}
    </LineItemsContext.Provider>
  )
}