import { useState, useEffect } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Button, Typography } from '@mui/material';

function findOrCreateRow(rows, {name, size, sku}, warehouses) {
    let row = rows.find(row => row.sku === sku);
    if (!row) {
        row = {
            name,
            qty: warehouses.reduce((acc, cur) => {
                acc[cur.name] = 0;
                return acc;
            }, {}),
            size,
            sku,
            total: 0,
        };
    }
    return row; 
}

const OrderTable = ({order, onOrderEdit, onDelete}) => {
    const [rows, setRows] = useState();
    const [warehouses, setWarehouses] = useState();
    const [total, setTotal] = useState();
    const [newItem, setNewItem] = useState({
        name: "",
        size: "",
        sku: "",
    });
    const largeScreen = useMediaQuery('(min-width:900px)');

    useEffect(() => {
        if (!order) return;
        const nextWarehouses = order.shipments.map(shipment => ({ name: shipment.warehouse, total: 0}));
        const nextRows = [];
        let nextTotal = 0;
        for (const shipment of order.shipments) {
            for (const item of shipment.items) {
                const row = findOrCreateRow(nextRows, item, nextWarehouses);
                row.qty[shipment.warehouse] += item.qty;
                row.total += item.qty;
                nextRows.push(row);
                const nextWarehouse = nextWarehouses.find(warehouse => warehouse.name === shipment.warehouse);
                nextWarehouse.total += item.qty;
                nextTotal += item.qty;
            }
        }
        setRows(nextRows.sort((a, b) => a.sku.localeCompare(b.sku)));
        setWarehouses(nextWarehouses.sort((a, b) => a.name.localeCompare(b.name)));
        setTotal(nextTotal);
    }, [order]);
    
    const handleNewItemChange = ({key, value}) => {
        setNewItem({...newItem, [key]: value});
    }

    const nextNewItem = () => {
        const currentSize = parseInt(newItem.size);
        if (!isNaN(currentSize) && currentSize <= 48) {
            return setNewItem({
                name: newItem.name,
                size: currentSize + 1,
                sku: newItem.sku.replace(/\d{2}$/, currentSize + 1),
            });
        }
        setNewItem({
            name: "",
            size: "",
            sku: "",
        });
    }

    return rows && warehouses && total !== undefined ? (
        <TableContainer>
        <Table stickyHeader size="small">
          <TableHead sx={{bgcolor: (theme) => theme.palette.common.black}}>
            <TableRow>
              {largeScreen && <TableCell>NAME</TableCell>}
              {largeScreen && <TableCell align="right">SIZE&nbsp;EU</TableCell>}
              <TableCell>SKU</TableCell>
              {warehouses.map(({name}) => {
                return <TableCell key={name} sx={{minWidth:'5rem'}} align="right">Qty {name.toUpperCase()}</TableCell>
              })}
              <TableCell align="right">TOTAL</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
                .map(({sku, size, name, qty, total}) => {
                return (
                    <TableRow
                        key={sku}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        {largeScreen && <TableCell align="left"><Typography noWrap>{name}</Typography></TableCell>}
                        {largeScreen && <TableCell align="right">{size}</TableCell>}
                        <TableCell><Typography noWrap>{sku.toUpperCase()}</Typography></TableCell>
                        {warehouses.map(({name: warehouse}) => {
                            return (<TableCell key={warehouse} align="right">
                            {!!onOrderEdit 
                                ? <TextField
                                    hiddenLabel
                                    id="filled-hidden-label-small"
                                    defaultValue={qty[warehouse] || 0}
                                    variant="filled"
                                    size="small"
                                    inputProps={{inputMode: 'numeric', pattern: '[0-9]*', style: {textAlign: 'right'}}}
                                    onChange={(evt) => onOrderEdit({warehouse, sku, name, size, qty:evt.target.value})}
                                    />
                                : qty[warehouse] || 0}
                            </TableCell>)
                        })}
                        <TableCell align="right">{total}</TableCell>
                        <TableCell align="right">{onDelete && <Button color='primary' onClick={() => onDelete(sku)}>X</Button>}</TableCell>
                    </TableRow>
                )
            })}
            {!!onOrderEdit && <TableRow
                key="new-sku"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                {largeScreen && <TableCell align="left">
                    <TextField
                        hiddenLabel
                        id="filled-hidden-label-small"
                        value={newItem.name}
                        variant="filled"
                        size="small"
                        placeholder='Name'
                        onChange = {(evt) => handleNewItemChange({key: 'name', value: evt.target.value})}
                        />
                    </TableCell>}
                {largeScreen && <TableCell align="right">
                    <TextField
                        hiddenLabel
                        id="filled-hidden-label-small"
                        value={newItem.size}
                        variant="filled"
                        size="small"
                        placeholder='Sz'
                        inputProps={{inputMode: 'numeric', pattern: '[0-9]*', style: {textAlign: 'right'}}}
                        onChange = {(evt) => handleNewItemChange({key: 'size', value: evt.target.value})}
                        />
                    </TableCell>}
                <TableCell><Typography noWrap>
                    <TextField
                        hiddenLabel
                        id="filled-hidden-label-small"
                        value={newItem.sku}
                        variant="filled"
                        size="small"
                        placeholder='SKU'
                        onChange = {(evt) => handleNewItemChange({key: 'sku', value: evt.target.value})}
                        />
                </Typography></TableCell>
                {warehouses.map(({warehouse}) => {
                    return (<TableCell key={warehouse} align="right">                             
                    </TableCell>)
                })}
                <TableCell align="right"></TableCell>
                <TableCell align="right"><Button disabled={!newItem.name || !newItem.size || !newItem.sku} onClick={() => {
                    onOrderEdit(newItem);
                    nextNewItem();
                }}>Add</Button></TableCell>
            </TableRow>}
            <TableRow
                key={"total"}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell align="left" colSpan={largeScreen ? 3 : 1}>TOTAL</TableCell>
                {warehouses.map(({name, total}) => {
                    return <TableCell key={name} align="right">{total}</TableCell>
                })}
                <TableCell align="right">{total}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    ) : "Loading";
}

export default OrderTable;