import './App.css';
import useAuth from './Hooks/useAuth';
import Dashboard from './Components/Dashboard';
import Login from './Components/Login';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Loading from './Components/Loading';
import { blueGrey } from '@mui/material/colors';
import { LineItemsProvider } from './Components/LineItemsContext';

function App() {
  const {user, loading} = useAuth();
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        xxl: 2400,
      }
    },
    palette: {
      mode: 'dark',
      archived: {
        main: blueGrey[500], // You can use MUI's built-in colors or custom hex values
        light: blueGrey[300],
        dark: blueGrey[700],
        contrastText: '#fff',
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <LineItemsProvider>
        <Container component="main" maxWidth='xxl'>
          <CssBaseline />
          {user ? <Dashboard user={user} /> : loading ? <Loading /> : <Login />}
        </Container>
      </LineItemsProvider>
    </ThemeProvider>
  );
}

export default App;
