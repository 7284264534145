export default [
  {
      handle: 'preparing',
      label: 'Preparing',
      description: 'Shipment is being prepared in Brazil',
  },
  {
      handle: 'shipped',
      label: 'Shipped',
      description: 'Shipment has been handed out to courrier and has a tracking number',
  },
  {
      handle: 'customs-in',
      label: 'At Customs',
      description: 'Shipment is being processed by customs',
  },
  {
      handle: 'customs-out',
      label: 'Out of Customs',
      description: 'Shipment has been cleared by customs and is on the way to the warehouse',
  },
  {
      handle: 'receiving',
      label: 'At Warhouse',
      description: 'Shipment has been received by the warehouse and is being processed for final delivery',
  },
  {
      handle: 'closed',
      label: 'Closed',
      description: 'Shipment has been shipped to customer',
  },
]