import { useEffect, useState } from 'react';
import { deleteOrder, editOrder, sendKlaviyoEvent, updateOrdersStatus } from '../../firebase';
import { useLineItems } from '../LineItemsContext';
import useAuth from '../../Hooks/useAuth';
import DownloadMenu from './DownloadMenu';
import statuses from '../../statuses';

import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TrackingLinks from './TrackingLinks';
import Payments from './Payments';
import NotificationsMenu from './NotificationsMenu';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const NewOrderCard = ({order}) => {
    const [localOrder, setLocalOrder] = useState();
    const [modal, setModal] = useState(null);
    const [deleting, setDeleting] = useState();
    const { lineItems } = useLineItems();
    const { user } = useAuth();

    useEffect(() => {
        if (!order) return;
        setLocalOrder(order);
    }, [order]);

    const handleModalClose = () => {
        setModal(null);
    }

    const handleCancelOrder = () => {
        setModal({
            prompt: (<>
                <p>Veuillez confirmer l'annulation de la commande {localOrder.reference}.</p>
            </>),
            successCallback: () => {
                cancelOrder(localOrder);
            },
        });
    }

    const cancelOrder = async (order) => {
        setDeleting(true);
        await deleteOrder(order);
        setDeleting(false);
    }

    const syncWithFirebase = (updatedOrder) => {
        editOrder(updatedOrder.id, updatedOrder);
    }

    const handleAddTracking= async (trackingNumber, warehouse) => {
        setLocalOrder((prevOrder) => {
            const found = prevOrder.shipments.some(shipment => shipment.warehouse === warehouse);
            if (!found) {
                throw new Error(`Shipment with name ${warehouse} not found`);
            }

            const updatedOrder = {
                ...prevOrder,
                shipments: prevOrder.shipments.map(shipment =>
                    shipment.warehouse === warehouse && !shipment.tracking?.includes(trackingNumber)
                        ? {
                            ...shipment,
                            tracking: shipment.tracking
                                ? [...shipment.tracking, trackingNumber]
                                : [trackingNumber],
                        }
                        : shipment
                ),
            };

            syncWithFirebase(updatedOrder);
            
            return updatedOrder;
        });
    }

    const handleRemoveTracking = async (trackingNumber, warehouse) => {
        setLocalOrder((prevOrder) => {
            const found = prevOrder.shipments.some(shipment => shipment.warehouse === warehouse);
            if (!found) {
                throw new Error(`Shipment with name ${warehouse} not found`);
            }

            const updatedOrder = {
            ...prevOrder,
            shipments: prevOrder.shipments.map((shipment) => 
                shipment.warehouse === warehouse
                    ? {
                        ...shipment,
                        tracking: shipment.tracking?.filter(
                            (track) => track !== trackingNumber
                        )
                    }
                    : shipment
                ),
            };

            syncWithFirebase(updatedOrder);
        
            return updatedOrder;
        });
    }

    const handleAddPayment= async (payment, warehouse) => {
        const timestampedPayment = {...payment, createdAt: Date.now()};
        setLocalOrder((prevOrder) => {
            let updatedOrder;
            if (warehouse !== undefined) {
                const found = prevOrder.shipments.some(shipment => shipment.warehouse === warehouse);
                if (!found) {
                    throw new Error(`Shipment with name ${warehouse} not found`);
                }

                updatedOrder = {
                    ...prevOrder,
                    shipments: prevOrder.shipments.map(shipment =>
                        shipment.warehouse === warehouse
                            ? {
                                ...shipment,
                                payments: shipment.payments
                                    ? [...shipment.payments, timestampedPayment]
                                    : [timestampedPayment],
                            }
                            : shipment
                    ),
                };
            } else {
                updatedOrder = {
                    ...prevOrder,
                    payments: prevOrder.payments
                        ? [...prevOrder.payments, timestampedPayment]
                        : [timestampedPayment],
                };
            }

            syncWithFirebase(updatedOrder);
            
            return updatedOrder;
        });
    }

    const handleRemovePayment= async (payment, warehouse) => {
        setLocalOrder((prevOrder) => {
            let updatedOrder;
            if (warehouse !== undefined) {
                const found = prevOrder.shipments.some(shipment => shipment.warehouse === warehouse);
                if (!found) {
                    throw new Error(`Shipment with name ${warehouse} not found`);
                }

                updatedOrder = {
                    ...prevOrder,
                    shipments: prevOrder.shipments.map(shipment =>
                        shipment.warehouse === warehouse
                            ? {
                                ...shipment,
                                payments: shipment.payments?.filter(
                                    (p) => p.createdAt !== payment.createdAt
                                ),
                            }
                            : shipment
                    ),
                };
            } else {
                updatedOrder = {
                    ...prevOrder,
                    payments: prevOrder.payments?.filter(
                        (p) => p.createdAt !== payment.createdAt
                    ),
                };
            }

            syncWithFirebase(updatedOrder);
            
            return updatedOrder;
        });
    }

    const prepareShopifyOrdersUpdate = (warehouse) => {
        const shipmentLineItemsMap = new Map();
        const shipment = localOrder.shipments.find(s => s.warehouse === warehouse);
        // Create a map of order-line-item id -> qty for lineItems in shipment
        shipment.lineItems.forEach(item => {
            shipmentLineItemsMap.set(item.docId, item.qty);
        });

        // Get all orderIds present in the shipment
        const shipmentOrderIds = new Set(
            lineItems
                .filter(doc => shipmentLineItemsMap.has(doc.id))
                .map(doc => (doc.data()).orderId)
        );

        // Create a map of orderId -> isComplete
        const orderStatusMap = new Map();

        lineItems
            .filter(doc => {
                const { brazilFulfillment, open } = doc.data();
                return brazilFulfillment && open;
            })
            .forEach(doc => {
            const {orderId, requiredQty, sentQty} = doc.data();
            if (!shipmentOrderIds.has(orderId)) return; // Ignore orders not in the shipment

            const missingQty = requiredQty - sentQty;

            if (!orderStatusMap.has(orderId)) {
                orderStatusMap.set(orderId, { isComplete: true, totalMissingQty: 0 });
            }

            // If missingQty > 0, mark the order as incomplete
            if (missingQty > 0) {
                const orderStatus = orderStatusMap.get(orderId);
                orderStatus.isComplete = false;
                orderStatus.totalMissingQty += missingQty;
            }
        });

        // Create final output array
        return Array.from(orderStatusMap.entries()).map(([orderId, status]) => ({
            shopifyOrderId: orderId,
            status: status.isComplete ? "complete" : "partial",
            missingQty: status.totalMissingQty
        }));
    }

    const handleSendEvent = async (eventName, warehouse) => {
        handleModalClose();
        sendKlaviyoEvent({eventName, orderId: localOrder.id, warehouse});

        // Format the customer orders data
        const orders = prepareShopifyOrdersUpdate(warehouse);
        updateOrdersStatus(orders, eventName);
    }

    const getStatusChip = () => {
        switch (localOrder.status) {
            case 'shipped':
                return <Chip label="ENVOYÉE" color='primary' />
            case 'closed':
                return <Chip label="CLOSED" />
        }
    }

    return (
        localOrder && <>
        <Badge invisible={!(localOrder.status === 'new')} badgeContent={' '} color="success">
        <Card key={localOrder.id} sx={{width: "100%", filter: deleting && 'blur(5px)'}}>
            <CardHeader
                action={<Stack spacing={1} direction="row">
                        {getStatusChip()}
                        {localOrder?.status === 'new' && <IconButton aria-label='cancel' color='error' onClick={handleCancelOrder}><DeleteForeverIcon /></IconButton>}
                    </Stack>}
                title={`${localOrder.reference} - ${localOrder.type.toUpperCase()}`}
                subheader={Intl.DateTimeFormat('fr-CA',{dateStyle: 'long'}).format(localOrder.createdAt.toDate())}
            />
            <CardContent>
                <Stack direction={'column'} spacing={2} alignItems="center" >
                <Payments payments={order.payments} onAddPayment={handleAddPayment} onRemovePayment={handleRemovePayment} />
                <Stack direction={'row'} justifyContent={'center'} spacing={2}>
                    {localOrder.shipments.map((shipment, index) => {
                        const eventName = shipment.statuses?.reduce((latest, stat) => {
                                // Compare the dates to find the latest
                                if (!latest || new Date(stat.date) > new Date(latest.date)) {
                                    return stat; // Update the latest status
                                }
                                return latest; // Keep the current latest
                            }, null)?.eventName; // Return the handle of the latest status

                        const shipmentStatus = eventName && (statuses.find(s => s.handle === eventName)).label;
                        return (
                            <Card key={shipment.warehouse || `default${index}`} elevation={0} sx={{p:'20px'}}>
                                <CardContent>
                                    <Stack alignItems={'center'} spacing={2}>
                                        <Stack alignItems={'center'} spacing={1} direction='column'>
                                            <Typography lineHeight={'1.2rem'} fontSize={'1.2rem'}>{shipment.warehouse.toUpperCase()}</Typography>
                                            {shipment.address && <>
                                                <Typography lineHeight={'0.8rem'} fontSize={'0.8rem'}>{shipment.address.name}</Typography>
                                            </>}
                                            <Typography lineHeight={'2.5rem'} fontSize={'2.5rem'}>{shipment.items.reduce((acc, cur) => acc + cur.qty || 0 , 0)}</Typography>
                                            <Typography lineHeight={'1rem'} fontSize={'1rem'}>PAIRES</Typography>
                                            {shipmentStatus && <Chip label={shipmentStatus} color='primary' />}
                                        </Stack>
                                        <Stack alignItems={'center'} width={"100%"} spacing={1} direction='column'>
                                            <Divider variant='middle' flexItem />
                                            <TrackingLinks shipment={shipment} onAddTracking={(tracking) => handleAddTracking(tracking, shipment.warehouse)} onRemoveTracking={(tracking) => handleRemoveTracking(tracking, shipment.warehouse)} />
                                            <Divider variant='middle' flexItem />
                                            <Payments payments={shipment.payments} onAddPayment={(payment) => handleAddPayment(payment, shipment.warehouse)} onRemovePayment={(payment) => handleRemovePayment(payment, shipment.warehouse)} />
                                        </Stack>
                                    </Stack>
                                </CardContent>
                                <CardActions disableSpacing>
                                    <Stack spacing={1} direction='row'>
                                        <DownloadMenu shipment={shipment} orderId={localOrder.id} />
                                        {(user?.isAdmin || user?.isStaff) && localOrder.type === 'stock' && 
                                            <NotificationsMenu shipment={shipment} orderId={order.id} onSendEvent={handleSendEvent} />}
                                    </Stack>
                                </CardActions>
                            </Card>
                        )
                    })}
                </Stack>
                </Stack>
            </CardContent>
        </Card> 
        </Badge>
        <Modal
            open={!!modal || false}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Stack direction={'column'} spacing={2}>
                    {modal?.prompt}
                    <Stack direction={'row'} justifyContent='flex-end' spacing={1}>
                        <Button onClick={handleModalClose} disabled={deleting}>Annuler</Button>
                        <Button onClick={modal?.successCallback} disabled={deleting} variant='contained'>Confirmer</Button>
                    </Stack>

                </Stack>
            </Box>
        </Modal>
        </>
    )
}

export default NewOrderCard;