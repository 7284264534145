import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';

import DataButton from "../DataButton";
import Stack from '@mui/material/Stack';
import TrackingLink from "./TrackingLink";

const TrackingLinks = ({shipment, onAddTracking, onRemoveTracking}) => {

    return (
        <>
        <Stack direction={'column'}>
            {shipment.tracking && shipment.tracking.map(trackingNumber => <TrackingLink key={trackingNumber} trackingNumber={trackingNumber} region={shipment.address?.country} onRemoveTracking={(trackingNumber) => onRemoveTracking(trackingNumber)} />)}
        </Stack>
        <DataButton
            size='small'
            startIcon={<AddIcon />}
            dataDescription="Tracking number"
            onDataChange={(newTracking) => onAddTracking(newTracking)}
            color={'primary'}
            variant={'outlined'}
            clearOnChange={true}
        >
            Tracking #
        </DataButton>
        </>
    )
}

export default TrackingLinks;