import { useEffect, useState } from 'react';
import csvDownload from 'json-to-csv-export';
import useAuth from '../../Hooks/useAuth';
import { generateLabels } from '../../utils/generateLabels';
import { db } from '../../firebase';
import { doc, getDoc } from "firebase/firestore";

import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PeopleIcon from '@mui/icons-material/People';
import { cloakRegion } from '../../utils/cloakRegion';


const DownloadMenu = ({shipment, orderId}) => {
    const { user } = useAuth();
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState([]);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDownloadCSV = () => {
        handleClose();

        csvDownload({
            data: shipment.items.map(item => {
                return {
                    name: item.name,
                    size: item.size,
                    sku: item.sku,
                    qty: item.qty,
                }
            }), 
            filename: `taygra-commande-${shipment.reference}.csv`,
            delimiter: ','
        });
    }

    const handleDownloadLabels = async () => {
        handleClose();
        setLoading([...loading, 'labels']);
        // Use lineItems to download labels from a STOCK order, use items to download labels from a prod order (Make sure the order creation script adds the region to the lineItem)
        const labelItems = [];
        if (shipment.lineItems) {
            // Use the lineItems to generate labels with individual order #
            for (const {docId, qty, regionCode = ''} of shipment.lineItems) {
                const [_, ref, sku] = docId.match(/^#[a-zA-Z]{0,2}(\d+)\|(.+)$/) || [];
                if (!(ref && sku)) {
                    throw new Error('Invalid ref or sku.');
                }
                for (let i = 0; i < qty; i++) {
                    labelItems.push({
                        sku,
                        regionCode,
                        lot: shipment.reference,
                        ref,
                    });
                }
            }
        } else {
            // Use the items to generate labels without individual order #
            for (const {sku, qty} of shipment.items) {
                for (let i = 0; i < qty; i++) {
                    labelItems.push({
                        sku,
                        regionCode: shipment.warehouse,
                        lot: shipment.reference,
                    });
                }
            }
        }

        const sortedItems = labelItems.sort((a, b) => {
            if (a.sku < b.sku) {
                return -1;
            }
            if (a.sku > b.sku) {
                return 1;
            }
            return 0;
        });
        
        const pdf = await generateLabels(sortedItems, "production"); // "production" template is now used for everything

        // Save pdf
        pdf.save(`Étiquettes_${formattedTimestamp()}`)
        setLoading([...loading.filter(item => item !== 'labels')]);
    }

    function formattedTimestamp() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
      
        return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
    }

    const exportCustomerData = async () => {
        handleClose();
        setLoading([...loading, 'customer-csv']);
        try {
            // Get the customer data
            const docRef = doc(db, `orders/${orderId}/private/${shipment.warehouse}`);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                console.log("Document data:", docSnap.data());
                csvDownload({
                    data: docSnap.data().customers.map(customer => ({
                        ...customer,
                        items: customer.items.map(item => `${item.orderName}|${item.sku}|${item.qty}`).join(', '),
                    })), 
                    filename: `customer-data-taygra-${shipment.reference}.csv`,
                    delimiter: ','
                });
              } else {
                // docSnap.data() will be undefined in this case
                throw new Error(`Couldn't find customer data for order ${shipment.reference}.`);
              }
        } catch(error) {
            console.log(error);
        }
        setLoading([...loading.filter(item => item !== 'customer-csv')]);
    }

    return (<>
        <Button
        id="basic-button"
        aria-controls={open ? 'download-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={<FileDownloadIcon />}
      >
        Download
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem disabled={!shipment.items?.length || loading.includes('labels')} onClick={handleDownloadLabels}>
            <ListItemIcon><LocalOfferIcon /></ListItemIcon> 
            <ListItemText>Labels</ListItemText>
        </MenuItem>
        <MenuItem disabled={!shipment.items?.length} onClick={handleDownloadCSV}>
            <ListItemIcon><InventoryIcon /></ListItemIcon>
            <ListItemText>CSV Products</ListItemText>
        </MenuItem>
        {(user?.isAdmin || user?.isStaff) && shipment.lineItems && 
            <MenuItem onClick={exportCustomerData}>
                <ListItemIcon><PeopleIcon /></ListItemIcon>
                <ListItemText>CSV Customers</ListItemText>
            </MenuItem>}
      </Menu>
    </>);
}

export default DownloadMenu;