import { useTheme } from '@mui/material/styles';
import { ReactComponent as DlhLogo } from './dhl-logo.svg';
import {ReactComponent as EmsLogo } from './ems-logo.svg';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import useAuth from '../../Hooks/useAuth';

const getTrackingInfo = (trackingNumber, region, theme) => {

  if (!trackingNumber) return;
  const firstNumber = trackingNumber.split(',')[0];
  if (firstNumber.match(/^[A-Z]{2}\d{9}[A-Z]{2}$/)) {
      // EMS
      let trackingUrl;
      switch(region) {
          case 'usa':
          case 'US':
              trackingUrl=`https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=3&text28777=&tLabels=${trackingNumber}`;
              break;
          case 'canada':
          case 'CA':
              trackingUrl=`https://www.canadapost-postescanada.ca/track-reperage/en#/details/${trackingNumber}`;
              break;
          case 'europe':
          case 'EU':
          case 'DE':
              trackingUrl=`https://www.dhl.com/us-en/home/tracking/tracking-parcel.html?submit=1&tracking-id=${trackingNumber}`;
              break;
          default:
              trackingUrl=`https://www.aftership.com/track?t=${trackingNumber}`;
      }
      return {
          shipperIcon: <EmsLogo fill={theme.palette.primary.main} height={"9px"} width={"30px"} />,
          trackingUrl,
      }
  }
  if (firstNumber.match(/^\d{10}$/)) {
      // DHL
      return {
          shipperIcon: <DlhLogo fill={theme.palette.primary.main} height={"9px"} />,
          trackingUrl: `https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=${trackingNumber}`
      }
  }
}

const TrackingLink = ({trackingNumber, region, onRemoveTracking}) => {
  const theme = useTheme();
  const {user} = useAuth();
  const trackingInfo = getTrackingInfo(trackingNumber, region, theme)

  return (<Chip
      size='small'
      sx={{cursor: 'pointer'}}
      component={'a'}
      href={trackingInfo?.trackingUrl}
      target={'_blank'}
      color='primary'
      variant='outlined'
      label={<>{trackingInfo?.shipperIcon}&nbsp;{trackingNumber}</>}
      onDelete={user?.isAdmin && (
          (e) => {
            e.preventDefault();
            onRemoveTracking(trackingNumber);
          })
        }
    />)
}

export default TrackingLink;