import { useEffect, useState } from 'react';
import csvDownload from 'json-to-csv-export';
import useAuth from '../../Hooks/useAuth';
import { generateLabels } from '../../utils/generateLabels';
import { db } from '../../firebase';
import { doc, getDoc } from "firebase/firestore";
import { useTheme } from '@mui/material/styles';
import statuses from '../../statuses';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import WarningIcon from '@mui/icons-material/Warning';


import NotificationsIcon from '@mui/icons-material/Notifications';

const nextStatus = (handle) => {
    const curIndex = statuses.findIndex(status => status.handle === handle);
    if (curIndex === statuses.length - 1) {
        return null;
    }
    if (curIndex === -1) {
        throw new Error(`Status '${handle}' not found`);
    }

    return statuses[curIndex + 1];
}

const NotificationsMenu = ({shipment, onSendEvent}) => {
    const { user } = useAuth();
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState([]);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSendEvent = (handle) => {
        handleClose();
        onSendEvent(handle, shipment.warehouse)
    }

    return (<>
        <Button
            id="notifications-button"
            aria-controls={open ? 'notifications-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            startIcon={<NotificationsIcon />}
        >
            Notifications
        </Button>
        <Menu
            id="notifications-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'notifications-button',
            }}
        >
            {statuses && statuses.map((status, index) => {
                let totalSuccessful = 0;
                let latestSuccessTimestamp = null;
                let latestFailTimestamp = null;
                let latestFailQty = 0;

                shipment.statuses?.filter(s => s.eventName === status.handle)
                    .forEach(s => {
                        totalSuccessful += s.successful;

                        if (s.successful > 0) {
                            if (!latestSuccessTimestamp || new Date(status.date) > new Date(latestSuccessTimestamp)) {
                                latestSuccessTimestamp = s.date;
                            }
                        }

                        if (s.failed > 0) {
                            if (!latestFailTimestamp || new Date(status.date) > new Date(latestFailTimestamp)) {
                                latestFailTimestamp = s.date;
                                latestFailQty = s.failed;
                            }
                        }
                });

                if (latestSuccessTimestamp && latestFailTimestamp) {
                    const successDate = new Date(latestSuccessTimestamp);
                    const failDate = new Date(latestFailTimestamp);

                    if (successDate > failDate) {
                        latestFailQty = 0;
                    }
                }

                const icon = totalSuccessful > 0 && latestFailQty === 0
                    ? <CheckIcon />
                    : totalSuccessful > 0 && latestFailQty > 0
                        ? <WarningIcon />
                        : null;

                return (
                    (user?.isAdmin || user?.isStaff) && 
                    <MenuItem key={status.handle} onClick={() => handleSendEvent(status.handle)}>
                        <ListItemIcon>{icon}</ListItemIcon>
                        <ListItemText>{status.label}</ListItemText>
                        <ListItemText sx={{textAlign: 'right', pl: '0.5rem', fontStyle: 'italic'}}>{latestSuccessTimestamp && Intl.DateTimeFormat('en-ca', {year: 'numeric', month: 'short', day: 'numeric'}).format(new Date(latestSuccessTimestamp))}</ListItemText>
                    </MenuItem>
                )
            })}
            
            
        </Menu>
    </>);
}

const NumberedCircle = ({number}) => {
    const theme = useTheme();
    const size = 24; // Typical size of MUI icons
    return (
        <Box
            sx={{
                width: size,
                height: size,
                borderRadius: "50%",
                borderColor: "white",
                borderWidth: "1px",
                borderStyle: "solid",
                color: theme.palette.text.primary,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '0.875rem', // Match MUI icon font size
                fontWeight: 'bold',
                boxShadow: 1,
            }}
        >
            {number + 1}
        </Box>
    );
}

export default NotificationsMenu;