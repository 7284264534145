import { useState, useEffect } from 'react'

import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const AddPaymentButton = ({children, startIcon, endIcon, initialData={amount: "", currency: 'USD', type: 'merchandise'}, onAddPayment = () => undefined, size, variant='outlined', color}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [formData, setFormData] = useState(initialData);

    const handleConfirmClick = () => {
        onAddPayment(formData);
        handleModalClose();
    }

    const handleModalOpen = () => {
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setModalOpen(false);
    }

    return (
        <>
        <Button 
            startIcon={startIcon}
            endIcon={endIcon}
            onClick={handleModalOpen}
            size={size}
            variant={variant}
            color={color}
        >
            {children}
        </Button>
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Stack direction={'column'} spacing={2}>
                    <FormControl required sx={{ m: 1, minWidth: 120 }} size='small'>
                        <TextField
                            id="amount"
                            label={"Amount *"}
                            onChange={(evt) => setFormData({...formData, amount: Math.round(parseFloat(evt.target.value) * 100)})}
                            fullWidth
                            defaultValue={initialData.amount}
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="currency-label">Currency</InputLabel>
                        <Select
                            labelId="currency-label"
                            id="currency"
                            value={formData?.currency}
                            defaultValue={initialData.currency}
                            label="Currency *"
                            onChange={(evt) => setFormData({...formData, currency: evt.target.value})}
                        >
                            <MenuItem value={'USD'}>USD</MenuItem>
                            <MenuItem value={'EUR'}>EUR</MenuItem>
                            <MenuItem value={'BRL'}>BRL</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="type-label">Type</InputLabel>
                        <Select
                            labelId="type-label"
                            id="type"
                            value={formData?.type}
                            defaultValue={initialData.type}
                            label="Type *"
                            onChange={(evt) => setFormData({...formData, type: evt.target.value})}
                        >
                            <MenuItem value={'merchandise'}>Merchandise</MenuItem>
                            <MenuItem value={'shipping'}>Shipping</MenuItem>
                            <MenuItem value={'duties'}>Duties</MenuItem>
                            <MenuItem value={'other'}>Other</MenuItem>
                        </Select>
                    </FormControl>
                    <Stack direction={'row'} justifyContent='flex-end' spacing={1}>
                        <Button onClick={handleModalClose}>Annuler</Button>
                        <Button onClick={handleConfirmClick} variant='contained'>Confirmer</Button>
                    </Stack>

                </Stack>
            </Box>
        </Modal>
        </>
    )
}

export default AddPaymentButton;