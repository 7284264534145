// Helper function to extract style from SKU
const extractStyle = (sku, includeSize) => {
  // Regex to match the style part (ignores the last two parts: region and optional size)
  const match =  sku.match(/(.*)-([A-Z]{2})(?:-(.+))?$/);

  if (!match) {
      throw new Error(`Invalid SKU format: '${sku}'`);
  }

  return match[1] + (includeSize && match[3] ? `-${match[3]}` : "");
}

export default extractStyle;