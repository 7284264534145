import { Box, Chip, Stack } from "@mui/material";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AddPaymentButton from "./AddPaymentButton";
import AddIcon from '@mui/icons-material/Add';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import useAuth from "../../Hooks/useAuth";

const iconMap = {
  'merchandise': <InventoryIcon />,
  'shipping': <LocalShippingIcon />,
  'duties': <AccountBalanceIcon />,
}

const getIcon = (type) => iconMap[type] || <MoreHorizIcon />;

const Payments = ({payments, onAddPayment, onRemovePayment}) => {
  const { user } = useAuth();

  return payments && (
    <>
      <Stack direction={'column'} gap={1} alignItems={'center'}>
        {payments.sort((a, b) => b - a).map((payment, index) => (
          <Chip
            key={`payment${index}`}
            size="small"
            color="success"
            icon={getIcon(payment.type)}
            label={<Stack direction={'row'} gap={1}><span>{Intl.DateTimeFormat('en-ca', {month: 'short', day: 'numeric'}).format(payment.createdAt)}</span><b>{`${(payment.amount/100).toFixed(2)} ${payment.currency}`}</b></Stack>}
            onDelete={user?.isAdmin && (() => onRemovePayment(payment))}
          />
        ))}
      </Stack>
      {user?.isAdmin && <AddPaymentButton
        size='small'
        startIcon={<AddIcon />}
        dataDescription="Payment"
        onAddPayment={onAddPayment}
        color={'primary'}
        variant={'outlined'}
      >
          Payment
      </AddPaymentButton>}
    </>
  )
}

export default Payments;